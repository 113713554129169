import React from 'react';
import imgYubinkyokuLogo from 'src/images/landing/postofficejp/yubinkyoku_logo.svg';
import imgIconTimes from 'src/images/landing/postofficejp/icon_times.png';
import imgPaidyLogo from 'src/images/landing/postofficejp/paidy_logo.png';
import styles from './MerchantIntro.module.scss';

const MerchantIntro = () => (
  <section>
    <div className={styles.container}>
      <img
        src={imgYubinkyokuLogo}
        alt="imgYubinkyokuLogo"
        className={styles.imgYubinkyokuLogo}
      />
      <img
        src={imgIconTimes}
        alt="imgIconTimes"
        className={styles.imgIconTimes}
      />
      <img
        src={imgPaidyLogo}
        alt="imgPaidyLogo"
        className={styles.imgPaidyLogo}
      />
    </div>
  </section>
);

export default MerchantIntro;

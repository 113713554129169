// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { SITE_METADATA } from 'src/constants';
import styles from 'src/styles/pages/landing.module.scss';
import ConversionTop from 'src/components/Landing/ConversionTop';
import Point from 'src/components/Landing/Point';
import Step from 'src/components/Landing/Step';
import Can from 'src/components/Landing/Can';
import ConversionBottom from 'src/components/Landing/ConversionBottom';
import Plus from 'src/components/Landing/Plus';
import Useful from 'src/components/Landing/Useful';
import Footer from 'src/components/Landing/Footer';
import TopBanner from 'src/components/Landing/TopBanner';
import MerchantIntro from 'src/components/Landing/MerchantIntro';
import Process from 'src/components/Landing/Process';
import UsageNote from 'src/components/Landing/UsageNote';
import PaidyPlusEKYC from 'src/components/Landing/PaidyPlusEKYC';

const PostOfficeJP = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            ogImage
          }
        }
      }
    `
  );

  const { siteUrl, ogImage } = site.siteMetadata;

  return (
    <div className={styles.postOfficeJP}>
      <Helmet
        title={SITE_METADATA.postOfficeJP.title}
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: `description`,
            content: SITE_METADATA.postOfficeJP.description,
          },
          {
            property: `og:title`,
            content: SITE_METADATA.postOfficeJP.title,
          },
          {
            property: `og:description`,
            content: SITE_METADATA.postOfficeJP.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: 'twitter:image',
            content: `${siteUrl}${ogImage}`,
          },
          {
            name: `twitter:title`,
            content: SITE_METADATA.postOfficeJP.title,
          },
          {
            name: `twitter:description`,
            content: SITE_METADATA.postOfficeJP.description,
          },
          {
            property: 'og:url',
            content: `${siteUrl}`,
          },
          {
            property: 'og:image',
            content: `${siteUrl}${ogImage}`,
          },
          {
            property: 'og:image:secure',
            content: `${siteUrl}${ogImage}`,
          },
          {
            property: 'og:image:type',
            content: `image/png`,
          },
        ]}
        script={[
          {
            async: 'async',
            defer: 'defer',
            innerHTML: `
            (function(d){
              var l = d.createElement("link");
              var s = d.getElementsByTagName("script")[0];
              l.rel = "stylesheet";
              l.href = "https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700,900&display=swap&subset=japanese";
              s.parentNode.insertBefore(l, s);
            })(document);
            `,
          },
        ]}
      />
      <TopBanner />
      <>
        <ConversionTop
          textPc={`ペイディアプリでお買い物を <br />
          もっと便利に。もっと楽しく。`}
          textSp={`ペイディアプリでお買い物を <br />
          もっと便利に。もっと楽しく。`}
        />
        <MerchantIntro />
        <UsageNote />
        <PaidyPlusEKYC />
        <Process />
        <Point />
        <Step />
        <Can />
        <Plus />
        <ConversionBottom
          textPc={`本人確認は <br />
        ペイディアプリから。`}
          textSp={`本人確認は <br />
        ペイディアプリから。`}
        />
        <Useful />
        <Footer />
      </>
    </div>
  );
};

export default PostOfficeJP;
